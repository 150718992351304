import {QuizItem} from "../components/quiz-item";
import {Column} from "../components/motion_mui";
import {useCallback, useState} from "react";


const quizData = [
    {
        question: "What did we eat first when we met in Tokyo?",
        answer: "sushi",
        hint: "it's a japanese food",
        image: "/assets/01_sushi.jpg"
    },
    {
        question: "Where did we take this photo?",
        answer: "imperial palace",
        hint: "a building where the emperor lives",
        image: "/assets/02_imperial_palace.jpg"
    },
    {
        question: "What is the nearest station to Thiemo's apartment?",
        answer: "nishidai",
        hint: "famous for its yearly fireworks",
        image: "/assets/03_tokyo_station.jpg"
    },
    {
        question: "What is behind this gate?",
        answer: "sensoji temple",
        hint: "a temple",
        image: "/assets/04_sensoji_temple.jpg"
    },
    {
        question: "From where is this photo?",
        answer: "skytree",
        hint: "a tower",
        image: "/assets/05_skytree-sunset.jpg"
    },
    {
        question: "To how many attractions did we go in Disney?",
        answer: "8",
        hint: "star wars, splash mountain, shooting gallery, western roller coaster, space roller coaster, parade, baymax, cinderella fairy tale hall",
        image: "/assets/06_disney.jpg"
    },
    {
        question: "What lovely animals like the Zushi beach, too?",
        answer: "jellyfish",
        hint: "it has no brain",
        image: "/assets/07_tokyo_beach.jpg"
    },
    {
        question: "What did we do after visiting the Metropolitan Government Building?",
        answer: "canyoning",
        hint: "a sport",
        image: "/assets/08_metropolitan.jpg"
    },
    {
        question: "What does the rock look like?",
        answer: "heart",
        hint: "it was so great <3",
        image: "/assets/09_canyoning.jpg"
    },
    {
        question: "What event took place before we had dinner?",
        answer: "fireworks",
        hint: "a lot of people attended, accompanied by a lot of police officers",
        image: "/assets/10_fireworks_dinner.jpg"
    },
    {
        question: "What do I like most about this place?",
        answer: "mirrors",
        hint: "Warning...",
        image: "/assets/11_light_museum.jpg"
    },
    {
        question: "What did we shoot in this game?",
        answer: "zombies",
        hint: "a lot of them",
        image: "/assets/12_gaming.jpg"
    },
    {
        question: "Where did we go after visiting the Tokyo Dome?",
        answer: "cinema",
        hint: "a place where you can watch movies",
        image: "/assets/13_tokyo_dome.jpg"
    },
    {
        question: "What movie did we watch?",
        answer: "Mission Impossible 7",
        hint: "by Tom Cruise",
        image: "/assets/14_cinema.jpg"
    },
    {
        question: "Which route did we walk up?",
        answer: "Fujinomiya",
        hint: "the steepest one",
        image: "/assets/15_fuji_top.jpg"
    },

]

export function QuizContainer(props: {
    onNext: () => void
}) {

    const [quizNumber, setQuizNumber] = useState(0)

    const setQuizNumberEnhanced = useCallback((nextQuizNumber: number) => {
        if (nextQuizNumber >= quizData.length) {
            props.onNext()
        } else {
            setQuizNumber(nextQuizNumber)
        }
    }, [props])

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"center"}
            sx={{
                width: "100%",
                maxHeight: "100%",
                p: 4,
                overflow: "auto"
            }}
        >
            {
                quizNumber < quizData.length &&
                <QuizItem
                    key={quizNumber}
                    question={quizData[quizNumber].question}
                    answer={quizData[quizNumber].answer}
                    hint={quizData[quizNumber].hint}
                    image={quizData[quizNumber].image}
                    quizNumber={quizNumber}
                    setQuizNumber={setQuizNumberEnhanced}
                    numberOfQuizzes={quizData.length}
                    onCheat={() => setQuizNumberEnhanced(quizData.length)}
                />
            }
        </Column>
    )
}
