import {QuizContainer24} from "./pages/quizContainer24";
import {Congratulations24} from "./pages/congratulations24";
import {Introduction24} from "./pages/introduction24";
import {useState} from "react";
import {Center, Column} from "./components/motion_mui";
import {Button} from "@mui/material";
import {Introduction} from "./pages/introduction";
import {QuizContainer} from "./pages/quizContainer";
import {Congratulations} from "./pages/congratulations";


export function AppPage() {

    const [year, setYear] = useState<number | null>(null)

    const [page, setPage] = useState(0)

    if (year === null) {
        return (
            <Center>
                <Column mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
                    <Button onClick={() => setYear(2023)}>
                        2023
                    </Button>
                    <Button sx={{mt: 2}} onClick={() => setYear(2024)}>
                        2024
                    </Button>
                </Column>
            </Center>
        )
    }
    if (year === 2023) {
        if (page === 0)
            return (
                <Introduction onNext={() => {
                    setPage(1)
                }}/>
            )

        if (page === 1)
            return (
                <QuizContainer onNext={() => setPage(2)}/>
            )

        return <Congratulations/>
    }

    if (year === 2024) {
        if (page === 0)
            return (
                <Introduction24 onNext={() => {
                    setPage(1)
                }}/>
            )

        if (page === 1)
            return (
                <QuizContainer24 onNext={() => setPage(2)}/>
            )
        return <Congratulations24/>
    }

    return null
}
