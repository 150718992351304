

import {QuizItem} from "../components/quiz-item";
import {Column} from "../components/motion_mui";
import {useCallback, useState} from "react";


const quizData = [
    {
        question: "How many days have passed since the last time we have seen us?",
        answer: "44",
        hint: "This photo was taken on September 19th",
        image: "/assets/2024/2309_sh.jpeg"
    },
    {
        question: "What is Xi'an is most famous for? (according to Krispin)",
        answer: "terracotta warriors",
        hint: "2 words in English",
        image: "/assets/2024/2309_xian.jpeg"
    },
    {
        question: "Mein ... Freund",
        answer: "heisser",
        hint: "Hot like a hot spring ...",
        image: "/assets/2024/2310_nikko_hot_spring.jpeg"
    },
    {
        question: "What Japanese word did your boyfriend learn this day?",
        answer: "katabase",
        hint: "I might have spelled it incorrectly.",
        image: "/assets/2024/2310_tokyo_baseball.jpeg"
    },
    {
        question: "And now we are in ...",
        answer: "Osaka",
        hint: "It was in December",
        image: "/assets/2024/2312_osaka_runner.jpeg"
    },
    {
        question: "We see us again in ... months",
        answer: "2",
        hint: "In winter time",
        image: "/assets/2024/2312_osaka_universal.jpeg"
    },
    {
        question: "This temple was built because of Buddha's ...",
        answer: "skull",
        hint: "We haven't actually seen it.",
        image: "/assets/2024/2402_nanjing_temple.jpeg"
    },
    {
        question: "Into what does an animal turn if you touch it?",
        answer: "butterflies",
        hint: "When two persons fall in love.",
        image: "/assets/2024/2402_sh_team_lab2.jpeg"
    },
    {
        question: "Tamami easily hikes on top of this mountain.",
        answer: "Fuji",
        hint: "We rode the bike here, too.",
        image: "/assets/2024/2403_fuji_bicycle.jpeg"
    },
    {
        question: "What was the name of Krispin's friend?",
        answer: "Georg",
        hint: "G...g",
        image: "/assets/2024/2403_tokyo_georg.jpeg"
    },
    {
        question: "We like ...",
        answer: "steak",
        hint: "It's soo tasty!",
        image: "/assets/2024/2403_tokyo_steak.jpeg"
    },
    {
        question: "What is the name of the roller coaster in the background?",
        answer: "TRON",
        hint: "The theme of the roller coaster is based on the movie Tron from 1982.",
        image: "/assets/2024/2405_sh_disney.jpeg"
    },
    {
        question: "How did the game between Germany and Spain end? (Answer is in the format of x:x)",
        answer: "2:1",
        hint: "Germany should have won.",
        image: "/assets/2024/2407_sh_bff.jpeg"
    },
    {
        question: "What does ETHZ stand for?",
        answer: "Eidgenössische Technische Hochschule Zürich",
        hint: "In English: Zurich Federal Institute of Technology",
        image: "/assets/2024/2408_zh_ethz.jpeg"
    },
]

export function QuizContainer24(props: {
    onNext: () => void
}) {

    const [quizNumber, setQuizNumber] = useState(0)

    const setQuizNumberEnhanced = useCallback((nextQuizNumber: number) => {
        if (nextQuizNumber >= quizData.length) {
            props.onNext()
        } else {
            setQuizNumber(nextQuizNumber)
        }
    }, [props])

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"center"}
            sx={{
                width: "100%",
                maxHeight: "100%",
                p: 4,
                overflow: "auto"
            }}
        >
            {
                quizNumber < quizData.length &&
                <QuizItem
                    key={quizNumber}
                    question={quizData[quizNumber].question}
                    answer={quizData[quizNumber].answer}
                    hint={quizData[quizNumber].hint}
                    image={quizData[quizNumber].image}
                    quizNumber={quizNumber}
                    setQuizNumber={setQuizNumberEnhanced}
                    numberOfQuizzes={quizData.length}
                    onCheat={() => setQuizNumberEnhanced(quizData.length)}
                />
            }
        </Column>
    )
}
