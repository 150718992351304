import ReactConfetti from "react-confetti";
import {Center, Column} from "../components/motion_mui";
import {Typography} from "@mui/material";


export function Congratulations() {

    return (
        <>
            <ReactConfetti>

            </ReactConfetti>
            <Center
                // mainAxisAlignment={"start"}
                // crossAxisAlignment={"stretch"}
                sx={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <Column
                    mainAxisAlignment={"start"}
                    crossAxisAlignment={"center"}
                    sx={{
                        p: 2
                    }}
                >
                    <Typography variant={"h2"} sx={{
                        whiteSpace: "break-spaces",
                        textAlign: "center",
                        fontWeight: 600,
                    }}>
                        Happy 22nd Birthday! 💖
                    </Typography>
                    <Typography variant={"h4"} sx={{
                        whiteSpace: "break-spaces",
                        textAlign: "center",
                        mt: 4
                    }}>
                        Thanks for so many lovely memories ❤️❤️❤️
                        We can do this quiz over and over again, until we see us again 😘
                    </Typography>

                </Column>
            </Center>
        </>

    )
}
