import React, {ForwardedRef, SyntheticEvent} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Avatar, Box, Grid, Paper, SxProps, Theme} from "@mui/material";
import {BoxProps} from "@mui/material/Box/Box";


export const MotionBox = motion(Box)
export const AvatarMotion = motion(Avatar)


export function BackgroundShadow({onTap, color}: { onTap?: () => void, color?: string }) {
    const transparent = "rgba(0, 0, 0, 0)"
    const shadow = color ? color : "rgba(0, 0, 0, 0.5)"
    return (
        <motion.div
            style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
            }}
            onTap={onTap}
            initial={{backgroundColor: transparent}}
            animate={{backgroundColor: shadow}}
            exit={{backgroundColor: transparent}}>
            <div style={{position: "sticky"}}/>
        </motion.div>

    )
}

interface BackgroundProps {
    onTap: () => void,
    active: boolean,
    left: number
}

export function Background(props: React.PropsWithChildren<BackgroundProps>) {
    const backgroundAnimatedProps = {
        backgroundColor: (() => {
            if (props.active) return "rgba(0, 0, 0, 0.5)";
            else return "rgba(0, 0, 0, 0.0)";
        })(),
    }

    const backgroundStyle = {
        visibility: props.active ? "visible" : "hidden",
        flexGrow: 1,
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
        left: props.left + "px"
    } as React.CSSProperties

    return <motion.div animate={backgroundAnimatedProps}
                       style={backgroundStyle}
                       onTap={() => props.onTap()}>
        {props.children}
    </motion.div>
}


interface FullSizedWindowProps {
    visible?: boolean,
    centerVertically?: boolean,
    centerHorizontally?: boolean,
    style?: React.CSSProperties,
    onTap?: (event: MouseEvent | TouchEvent | PointerEvent) => void
}

export function FullSizedWindow(props: React.PropsWithChildren<FullSizedWindowProps & BoxProps>) {
    const {visible, centerVertically, centerHorizontally, style, onTap, sx, ...otherProps} = props;
    const fullSizedWindowStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: centerHorizontally ? 'center' : 'start',
        alignItems: centerVertically ? 'center' : 'start',
        visibility: visible ? 'visible' : 'hidden',
        ...style,
    } as React.CSSProperties;

    const sx2 = {
        ...fullSizedWindowStyle,
        ...sx,
        ...style
    } as SxProps<Theme>;

    return <Box sx={sx2} {...otherProps}>
        {props.children}
    </Box>
}

// export const FullSizedWindowMotion = motion(FullSizedWindow);


// interface CenterProps {
//     style?: React.CSSProperties
// }

export const Center = React.forwardRef<HTMLDivElement, React.PropsWithChildren<BoxProps>>(
    (props, ref) => {

        const centerBaseProps = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
        }
        const centerStyle = {
            ...centerBaseProps,
            ...props.style,
            ...props.sx
        } as SxProps<Theme>;
        return <MotionBox ref={ref} sx={centerStyle}>
            {props.children}
        </MotionBox>

    });

export const CenterMotion = motion(Center);

type mainAxisAlignment = "center" | "stretch" | "start" | "end" | "spaceAround" | "spaceBetween" | "spaceEvenly";
type crossAxisAlignment = "center" | "stretch" | "start" | "end" | "baseline"

function crossAxisAlignmentToAlignItems(crossAxisAlignment: crossAxisAlignment) {
    switch (crossAxisAlignment) {
        case "center":
            return "center";
        case "start":
            return "flex-start";
        case "end":
            return "flex-end";
        case "stretch":
            return "stretch";
        case "baseline":
            return "baseline"
        default:
            return "flex-start";
    }
}

function mainAxisAlignmentToJustifyContent(mainAxisAlignment: mainAxisAlignment) {
    switch (mainAxisAlignment) {
        case "center":
            return "center";
        case "start":
            return "flex-start";
        case "end":
            return "flex-end";
        case "stretch":
            return "stretch";
        case "spaceAround":
            return "space-around";
        case "spaceBetween":
            return "space-between";
        case "spaceEvenly":
            return "space-evenly";
        default:
            return "flex-start";
    }
}

type ColumnProps = {
    mainAxisAlignment: mainAxisAlignment,
    crossAxisAlignment: crossAxisAlignment,
} & BoxProps

export const Column = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ColumnProps>>(
    (props, ref) => {
        const {crossAxisAlignment, mainAxisAlignment, sx, ...otherProps} = props
        const columnStyle = {
            display: "flex",
            flexDirection: "column",
            alignItems: crossAxisAlignmentToAlignItems(crossAxisAlignment),
            justifyContent: mainAxisAlignmentToJustifyContent(mainAxisAlignment),
            ...sx
        } as SxProps<Theme>;

        return <Box sx={columnStyle} {...otherProps} ref={ref}>
            {props.children}
        </Box>
    });

export const ColumnMotion = motion(Column);

type RowProps = {
    mainAxisAlignment: mainAxisAlignment,
    crossAxisAlignment: crossAxisAlignment
} & BoxProps

export const Row = React.forwardRef<HTMLDivElement, React.PropsWithChildren<RowProps>>(
    (props, ref) => {
        const {crossAxisAlignment, mainAxisAlignment, sx, ...otherProps} = props
        const rowStyle = {
            display: "flex",
            flexDirection: "row",
            alignItems: crossAxisAlignmentToAlignItems(props.crossAxisAlignment),
            justifyContent: mainAxisAlignmentToJustifyContent(props.mainAxisAlignment),
            ...sx
        } as SxProps<Theme>;

        return <Box sx={rowStyle} {...otherProps} ref={ref}>
            {props.children}
        </Box>
    });

export const RowMotion = motion(Row);

// TODO Smart column with smart loading and load animation

interface ListViewBuilder {
    (index: number): React.Component;
}

interface ListViewProps {
    sx?: SxProps<Theme>,
    itemCount?: number,
    itemBuilder?: ListViewBuilder
}

export const ListView = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ListViewProps>>(
    (props, ref) => {

        // TODO use builder
        const {sx, ...restProps} = props;

        const listViewStyle = {
            flexGrow: 1,
            ...sx
        } as SxProps<Theme>;

        return <Column ref={ref} mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}
                       sx={listViewStyle} {...restProps}>
            {props.children}
        </Column>
    });

export const ListViewMotion = motion(ListView);

export const PaperMotion = motion(Paper);

export const GridMotion = motion(Grid);


// TODO MUI Grid








