import {
    Alert, Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia, Collapse,
    FormControlLabel, LinearProgress, Snackbar,
    Switch, TextField,
    Typography
} from "@mui/material";
import {useState} from "react";
import {Column, Row} from "./motion_mui";


export function QuizItem({image, question, answer, hint, quizNumber, setQuizNumber, numberOfQuizzes, onCheat}: {
    question: string,
    answer: string,
    hint: string,
    image: string,
    quizNumber: number,
    setQuizNumber: (quizNumber: number) => void,
    numberOfQuizzes: number,
    onCheat?: () => void,
}) {


    const [isHintShown, setIsHintShown] = useState(false)

    const [userAnswer, setUserAnswer] = useState("")

    const [isCorrect, setIsCorrect] = useState(false)

    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
    const handleCloseSnackbar = () => setIsSnackbarOpen(false)

    return (
        <Box sx={{
            width: "100%",
            maxWidth: "400px",
        }}>
            <Card sx={{
                width: "100%",
            }}>
                <LinearProgress variant="determinate" value={(quizNumber + (isCorrect ? 1 : 0)) / numberOfQuizzes * 100} />

                <CardMedia
                    sx={{
                        height: 260,
                        width: "100%"
                    }}
                    image={image}
                    title={"helper image"}
                />

                <CardContent>

                    <Column
                        mainAxisAlignment={"start"}
                        crossAxisAlignment={"stretch"}
                        sx={{width: "100%", height: "100%"}}
                    >
                        <Typography gutterBottom variant="h5" component="div">
                            {question}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch component={"div"} checked={isHintShown} onChange={
                                    (e) => setIsHintShown(e.target.checked)
                                } name="show hint"/>
                            }
                            label="Show Hint"
                        />
                        <Collapse in={isHintShown}>
                            <Alert severity="info">
                                {hint}
                            </Alert>
                        </Collapse>
                        <TextField
                            size={"small"}
                            sx={{mt: 2}}
                            variant={"filled"}
                            onChange={(e) => {
                                setUserAnswer(e.target.value)
                            }}
                            value={userAnswer}
                            label={"Answer"}
                        />
                        <Button
                            variant={"contained"}
                            sx={{
                                width: "100%",
                                mt: 2
                            }}
                            onClick={() => {
                                if (userAnswer.toLowerCase() === "cheat" && onCheat) {
                                    onCheat()
                                }
                                if (userAnswer.toLowerCase() === answer.toLowerCase()) {
                                    setIsSnackbarOpen(true)
                                    setIsCorrect(true)
                                } else {
                                    setIsSnackbarOpen(true)
                                    setIsCorrect(false)
                                }
                            }}
                        >
                            Submit
                        </Button>
                    </Column>

                </CardContent>
                <Box
                    sx={{
                        flexGrow: 1,
                    }}
                />
                <CardActions sx={{
                    width: "100%",
                }}>

                    <Row
                        mainAxisAlignment={"spaceBetween"}
                        crossAxisAlignment={"start"}
                        sx={{
                            width: "100%",
                        }}
                    >
                        {
                            quizNumber > 0 ? (
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setQuizNumber(quizNumber - 1)
                                    }}
                                >
                                    Back
                                </Button>
                            ) : <Box/>
                        }
                        <Button
                            disabled={!isCorrect}
                            variant={"contained"}
                            size="small"
                            onClick={() => {
                                setQuizNumber(quizNumber + 1)
                            }}
                        >
                            {
                                numberOfQuizzes === quizNumber + 1 ? "Finish" : "Next"
                            }
                        </Button>
                    </Row>

                </CardActions>

            </Card>
            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} open={isSnackbarOpen && isCorrect} autoHideDuration={1000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{width: '100%'}}>
                    Correct!
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} open={isSnackbarOpen && !isCorrect} autoHideDuration={1000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="warning" sx={{width: '100%'}}>
                    Incorrect :/ Please try again - you can do it!
                </Alert>
            </Snackbar>
        </Box>


    )
}
