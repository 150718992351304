import {Button, Typography} from "@mui/material";
import {Center, Column} from "../components/motion_mui";


export function Introduction(props: {
    onNext: () => void
}) {

    return (
        <Center
            // mainAxisAlignment={"start"}
            // crossAxisAlignment={"stretch"}
            sx={{
                width: "100%",
                height: "100%",
            }}
        >
            <Column
                mainAxisAlignment={"start"}
                crossAxisAlignment={"center"}
                sx={{
                    p: 2
                }}
            >
                <Typography variant={"h2"} sx={{
                    whiteSpace: "break-spaces",
                    textAlign: "center",
                    fontWeight: 600,
                }}>
                    A little quiz about our days in Tokyo!
                </Typography>
                <Button onClick={props.onNext} variant={"contained"} sx={{
                    mt: 4
                }}>
                    Start
                </Button>
            </Column>
        </Center>
    )
}
